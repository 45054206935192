import React, { useContext, useEffect } from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'

import { SkyroamPortfolio } from '_templates'
import { AppContext } from '_context'
import variables from '_config/css-variables'

const SkyroamCaseStudy = ({ data }) => {
  const { setNavbarColor, setNavbarLight } = useContext(AppContext)
  const content = data.contentfulSkyroamCaseStudy

  useEffect(() => {
    setNavbarColor(variables['skyroam-primary'])
    setNavbarLight(false)
  }, [setNavbarColor, setNavbarLight])

  return (
    <SkyroamPortfolio
      navbarColorOverride={variables['skyroam-primary']}
      content={content}
      pageTitle={content.pageTitle}
      pageDescription={content.pageDescription}
      pageThumbnailUrl={content.pageThumbnail.file.url}
      heroDescription={content.heroDescription.internal.content}
      heroImage={content.heroImage.fluid}
      heroImageAlt={content.heroImage.description}
      challengeTitle={content.challengeTitle}
      challengeDescription={content.challengeDescription.internal.content}
      platformTitle={content.platformTitle}
      platformDescription={content.platformDescription.internal.content}
      platformImage={content.platformImage.fluid}
      platformImageAlt={content.platformImage.description}
      serverTitle={content.serverTitle}
      serverDescription={content.serverDescription.internal.content}
      currencyTitle={content.currencyTitle}
      currencyDescription={content.currencyDescription.internal.content}
      currencyImage={content.currencyImage.fluid}
      currencyImageAlt={content.currencyImage.description}
      marketingTitle={content.marketingTitle}
      marketingDescription={content.marketingDescription.internal.content}
      marketingImage={content.marketingImage.fluid}
      marketingImageAlt={content.marketingImage.description}
      integrationTitle={content.integrationTitle}
      integrationDescription={content.integrationDescription.internal.content}
      integrationImage={content.integrationImage.fluid}
      integrationImageAlt={content.integrationImage.description}
      testimonials={content.testimonials}
      workingTogether={content.workingTogether}
      sales={content.sales}
      seriesC={content.seriesC}
      info={content.info}
      proudCases={content.proudCases}
    />
  )
}

export const query = graphql`
  query SkyroamCaseStudyContent($locale: String) {
    contentfulSkyroamCaseStudy(fields: { localeKey: { eq: $locale } }) {
      pageTitle
      pageDescription
      pageThumbnail {
        file {
          url
        }
      }
      heroDescription {
        internal {
          content
        }
      }
      heroImage {
        description
        fluid {
          ...GatsbyContentfulFluid_withWebp_noBase64
        }
      }
      challengeTitle
      challengeDescription {
        internal {
          content
        }
      }
      platformTitle
      platformDescription {
        internal {
          content
        }
      }
      platformImage {
        description
        fluid {
          ...GatsbyContentfulFluid_withWebp_noBase64
        }
      }
      serverTitle
      serverDescription {
        internal {
          content
        }
      }
      currencyTitle
      currencyDescription {
        internal {
          content
        }
      }
      currencyImage {
        description
        fluid {
          ...GatsbyContentfulFluid_withWebp_noBase64
        }
      }
      marketingTitle
      marketingDescription {
        internal {
          content
        }
      }
      marketingImage {
        description
        fluid {
          src
          srcSet
        }
      }
      integrationTitle
      integrationDescription {
        internal {
          content
        }
      }
      integrationImage {
        description
        fluid {
          src
          srcSet
        }
      }
      testimonials {
        id
        jobDescription
        user
        text {
          internal {
            content
          }
        }
        userImage {
          fixed(width: 64, height: 64, quality: 80) {
            src
            srcSet
          }
        }
      }
      workingTogether
      sales
      seriesC
      info
      proudCases {
        id
        title
        main
        platforms {
          internal {
            content
          }
        }
        projectColor
        projectName
        thumbnail {
          description
          fixed {
            src
            srcSet
          }
          file {
            details {
              image {
                width
                height
              }
            }
          }
        }
      }
    }
  }
`

SkyroamCaseStudy.propTypes = {
  data: PropTypes.instanceOf(Object).isRequired,
}

export default SkyroamCaseStudy
